export function formatNum(num) {
  if (!num || num === 0) {
    return '0'
  }
  let result = []
  let counter = 0
  num = (num || 0).toString().split('')
  for (let i = num.length - 1; i >= 0; i--) {
    counter++
    result.unshift(num[i])
    if (!(counter % 3) && i !== 0) {
      result.unshift(',')
    }
  }
  return result.join('')
}
//  获取近几年的column字段
export function getRecentYears(
  number,
  prop,
  title,
  unReverse,
  noYear,
  notSortable
) {
  const yearArray = []
  const date = new Date()
  const currentYear = date.getFullYear()
  for (let i = 0; i < number; i += 1) {
    let tempYearObj = {}
    tempYearObj[prop] = `count${i + 1}`
    tempYearObj[title] = `${currentYear - i}${noYear ? '' : '年'}`
    tempYearObj[notSortable] = true
    yearArray.push(tempYearObj)
  }
  if (!unReverse) {
    yearArray.reverse()
  }
  return yearArray
}
// 时长处理
export function transTime(value) {
  var time = ''
  var h = parseInt(value / 3600)
  value %= 3600
  var m = parseInt(value / 60)
  var s = parseInt(value % 60)
  if (h > 0) {
    time = formatTime(h + ':' + m + ':' + s)
  } else {
    time = formatTime(0 + ':' + m + ':' + s)
  }
  return time
}
function formatTime(value) {
  var time = ''
  var s = value.split(':')
  var i = 0
  for (; i < s.length - 1; i++) {
    time += s[i].length === 1 ? '0' + s[i] : s[i]
    time += ':'
  }
  time += s[i].length === 1 ? '0' + s[i] : s[i]
  return time
}
// 判断字段是否存在url中并返回对应数据
export const getQueryVariable = (variable) => {
  var query = window.location.search.substring(1)
  var vars = query.split('&')
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=')
    if (pair[0] === variable) {
      return pair[1]
    }
  }
  return false
}
// 处理微网刊跳转地址
export function WeChatAllJumpUrl(magId, taskId) {
  const origin = 'http://www.das.publish.founderss.cn'
  let url = ''
  if (magId && taskId) {
    url =
      origin +
      '/otherPages/Microblog/html/catalogDetails.html?magId=' +
      magId +
      '&taskId=' +
      taskId
  } else if (magId && !taskId) {
    url =
      origin + '/otherPages/Microblog/html/catalogDetails.html?magId=' + magId
  } else if (!magId && taskId) {
    url =
      origin + '/otherPages/Microblog/html/catalogDetails.html?taskId=' + taskId
  } else {
    return
  }
  return url
}

// 处理微网刊类型
export function getWeChatType(type) {
  // 整期：1 专题：2 通知：3
  let result
  if (type == 1) {
    result = '整期'
  } else if (type == 2) {
    result = '专题'
  } else if (type == 3) {
    result = '通知'
  }
  console.log(result, 'result')
  return result
}
export const geoData = {
  上海: [121.4648, 31.2891],
  东莞: [113.8953, 22.901],
  东营: [118.7073, 37.5513],
  中山: [113.4229, 22.478],
  临汾: [111.4783, 36.1615],
  临沂: [118.3118, 35.2936],
  丹东: [124.541, 40.4242],
  丽水: [119.5642, 28.1854],
  乌鲁木齐: [87.9236, 43.5883],
  佛山: [112.8955, 23.1097],
  保定: [115.0488, 39.0948],
  兰州: [103.5901, 36.3043],
  包头: [110.3467, 41.4899],
  北京: [116.4551, 40.2539],
  北海: [109.314, 21.6211],
  南京: [118.8062, 31.9208],
  南宁: [108.479, 23.1152],
  南昌: [116.0046, 28.6633],
  南通: [121.1023, 32.1625],
  厦门: [118.1689, 24.6478],
  台州: [121.1353, 28.6688],
  合肥: [117.29, 32.0581],
  呼和浩特: [111.4124, 40.4901],
  咸阳: [108.4131, 34.8706],
  哈尔滨: [127.9688, 45.368],
  唐山: [118.4766, 39.6826],
  嘉兴: [120.9155, 30.6354],
  大同: [113.7854, 39.8035],
  大连: [122.2229, 39.4409],
  天津: [117.4219, 39.4189],
  太原: [112.3352, 37.9413],
  威海: [121.9482, 37.1393],
  宁波: [121.5967, 29.6466],
  宝鸡: [107.1826, 34.3433],
  宿迁: [118.5535, 33.7775],
  常州: [119.4543, 31.5582],
  广州: [113.5107, 23.2196],
  廊坊: [116.521, 39.0509],
  延安: [109.1052, 36.4252],
  张家口: [115.1477, 40.8527],
  徐州: [117.5208, 34.3268],
  德州: [116.6858, 37.2107],
  惠州: [114.6204, 23.1647],
  成都: [103.9526, 30.7617],
  扬州: [119.4653, 32.8162],
  承德: [117.5757, 41.4075],
  拉萨: [91.1865, 30.1465],
  无锡: [120.3442, 31.5527],
  日照: [119.2786, 35.5023],
  昆明: [102.9199, 25.4663],
  杭州: [119.5313, 29.8773],
  浙江: [119.5313, 29.8773],
  枣庄: [117.323, 34.8926],
  柳州: [109.3799, 24.9774],
  株洲: [113.5327, 27.0319],
  武汉: [114.3896, 30.6628],
  汕头: [117.1692, 23.3405],
  江门: [112.6318, 22.1484],
  沈阳: [123.1238, 42.1216],
  沧州: [116.8286, 38.2104],
  河源: [114.917, 23.9722],
  泉州: [118.3228, 25.1147],
  泰安: [117.0264, 36.0516],
  泰州: [120.0586, 32.5525],
  济南: [117.1582, 36.8701],
  济宁: [116.8286, 35.3375],
  海口: [110.3893, 19.8516],
  淄博: [118.0371, 36.6064],
  淮安: [118.927, 33.4039],
  深圳: [114.5435, 22.5439],
  清远: [112.9175, 24.3292],
  温州: [120.498, 27.8119],
  渭南: [109.7864, 35.0299],
  湖州: [119.8608, 30.7782],
  湘潭: [112.5439, 27.7075],
  滨州: [117.8174, 37.4963],
  潍坊: [119.0918, 36.524],
  烟台: [120.7397, 37.5128],
  玉溪: [101.9312, 23.8898],
  珠海: [113.7305, 22.1155],
  盐城: [120.2234, 33.5577],
  盘锦: [121.9482, 41.0449],
  河北: [114.4995, 38.1006],
  福州: [119.4543, 25.9222],
  秦皇岛: [119.2126, 40.0232],
  绍兴: [120.564, 29.7565],
  聊城: [115.9167, 36.4032],
  肇庆: [112.1265, 23.5822],
  舟山: [122.2559, 30.2234],
  苏州: [120.6519, 31.3989],
  莱芜: [117.6526, 36.2714],
  菏泽: [115.6201, 35.2057],
  营口: [122.4316, 40.4297],
  葫芦岛: [120.1575, 40.578],
  衡水: [115.8838, 37.7161],
  衢州: [118.6853, 28.8666],
  西宁: [101.4038, 36.8207],
  西安: [109.1162, 34.2004],
  贵阳: [106.6992, 26.7682],
  连云港: [119.1248, 34.552],
  邢台: [114.8071, 37.2821],
  邯郸: [114.4775, 36.535],
  郑州: [113.4668, 34.6234],
  鄂尔多斯: [108.9734, 39.2487],
  重庆: [107.7539, 30.1904],
  金华: [120.0037, 29.1028],
  铜川: [109.0393, 35.1947],
  镇江: [119.4763, 31.9702],
  长春: [125.8154, 44.2584],
  长沙: [113.0823, 28.2568],
  长治: [112.8625, 36.4746],
  阳泉: [113.4778, 38.0951],
  青岛: [120.4651, 36.3373],
  韶关: [113.7964, 24.7028],
  银川: [106.26667, 38.46667],
  克拉玛依: [84.87, 45.6],
  香港特别行政区: [114.15, 22.15],
  石家庄: [114.29, 38.03],
  滁州: [119.0, 32.41],
  焦作: [113.13, 35.14],
  赣州: [114.93, 25.83],
  驻马店: [114.02, 32.98],
  赤峰: [118.92, 42.27],
  桂林: [110.28, 25.28],
  平顶山: [113.18, 33.77],
  开封: [114.3, 34.8],
  漳州: [117.65, 24.52],
  黄冈: [114.87, 30.45],
  酒泉: [98.52, 39.75],
  日喀则: [115.469381, 35.246531],
  阜新: [121.67, 42.02],
  黄山: [118.33, 29.72],
  果洛藏族自治州: [100.23, 34.48],
  牡丹江: [129.6, 44.58],
  芜湖: [118.38, 31.33],
  阜阳: [115.82, 32.9],
  武威: [102.63, 37.93],
  兴安盟: [122.05, 46.08],
  九江: [116.0, 29.7],
  临沧: [100.08, 23.88],
  朔州: [112.43, 39.33],
  吉安: [114.98, 27.12],
  张掖: [100.45, 38.93],
  衡阳: [112.57, 26.9],
  巴音郭楞蒙古自治州: [86.15, 41.77],
  上饶: [117.97, 28.45],
  桃园: [121.3, 24.97],
  信阳: [114.07, 32.13],
  邵阳: [111.47, 27.25],
  塔城地区: [82.98, 46.75],
  佳木斯: [130.37, 46.82],
  安庆: [117.05, 30.53],
  甘孜藏族自治州: [101.97, 30.05],
  郴州: [113.02, 25.78],
  吕梁: [111.13, 37.52],
  巴彦淖尔: [107.42, 40.75],
  襄阳: [112.144146, 32.042426],
  龙岩: [117.03, 25.1],
  潮州: [116.62, 23.67],
  阿坝藏族羌族自治州: [102.22, 31.9],
  凉山彝族自治州: [102.27, 27.9],
  淮南: [117.0, 32.63],
  黑河: [127.48, 50.25],
  海北藏族自治州: [100.9, 36.97],
  天水: [105.72, 34.58],
  宣城: [118.75, 30.95],
  晋中: [112.75, 37.68],
  马鞍山: [118.5, 31.7],
  揭阳: [116.37, 23.55],
  琼中黎族苗族自治县: [109.839996, 19.03557],
  忻州: [112.73, 38.42],
  怀化: [110.0, 27.57],
  林芝: [94.48391, 29.57562],
  汉中: [107.02, 33.07],
  宜昌: [111.28, 30.7],
  铜仁: [109.191555, 27.718346],
  濮阳: [115.03, 35.77],
  石河子: [86.03, 44.3],
  仙桃: [113.45, 30.37],
  湛江: [110.35, 21.27],
  榆林: [109.73, 38.28],
  安阳: [114.38, 36.1],
  新乡: [113.9, 35.3],
  商丘: [115.65, 34.45],
  毕节: [105.28501, 27.301693],
  黔西南布依族苗族自治州: [104.897971, 25.08812],
  湘西土家族苗族自治州: [109.73, 28.32],
  阿里地区: [80.1, 32.5],
  台北: [121.5, 25.03],
  高雄: [120.28, 22.62]

  /*
 北京市-北京市
上海市-上海市
江苏省-南京市
湖北省-武汉市
浙江省-杭州市
陕西省-西安市
广东省-广州市
四川省-成都市
山东省-青岛市
重庆市-重庆市
广东省-深圳市
湖南省-长沙市
甘肃省-兰州市
云南省-昆明市
安徽省-合肥市
江苏省-徐州市
福建省-厦门市
江苏省-宿迁市
河南省-郑州市
天津市-天津市
江苏省-常州市
广西壮族自治区-南宁市
山东省-济南市
福建省-福州市
新疆维吾尔自治区-乌鲁木齐市
江苏省-苏州市
贵州省-贵阳市
广东省-惠州市
黑龙江省-哈尔滨市
内蒙古自治区-呼和浩特市
江西省-南昌市
浙江省-宁波市
湖南省-湘潭市
香港特别行政区-香港特别行政区
浙江省-舟山市
山东省-烟台市
吉林省-长春市
河北省-廊坊市
江苏省-无锡市
河北省-保定市
广东省-东莞市
河北省-石家庄市
安徽省-滁州市
河南省-焦作市
江西省-赣州市
广东省-珠海市
辽宁省-大连市
辽宁省-葫芦岛市
江苏省-淮安市
河南省-驻马店市
内蒙古自治区-赤峰市
宁夏回族自治区-银川市
广东省-佛山市
江苏省-盐城市
山东省-泰安市
广西壮族自治区-桂林市
河南省-平顶山市
山东省-东营市
辽宁省-沈阳市
浙江省-湖州市
河南省-开封市
山西省-太原市
福建省-漳州市
湖北省-黄冈市
江苏省-扬州市
甘肃省-酒泉市
西藏自治区-日喀则市
辽宁省-阜新市
安徽省-黄山市
青海省-果洛藏族自治州
黑龙江省-牡丹江市
西藏自治区-拉萨市
河北省-邯郸市
安徽省-芜湖市
河北省-秦皇岛市
安徽省-阜阳市
陕西省-咸阳市
山东省-日照市
甘肃省-武威市
内蒙古自治区-兴安盟
江苏省-镇江市
江西省-九江市
河北省-沧州市
浙江省-温州市
云南省-临沧市
山西省-朔州市
江西省-吉安市
甘肃省-张掖市
湖南省-衡阳市
广东省-清远市
新疆维吾尔自治区-巴音郭楞蒙古自治州
江西省-上饶市
海南省-海口市
广东省-肇庆市
台湾省-桃园市
河南省-信阳市
湖南省-邵阳市
山东省-菏泽市
新疆维吾尔自治区-塔城地区
黑龙江省-佳木斯市
安徽省-安庆市
四川省-甘孜藏族自治州
山东省-济宁市
湖南省-郴州市
山西省-吕梁市
内蒙古自治区-巴彦淖尔市
江苏省-泰州市
湖北省-襄阳市
福建省-龙岩市
内蒙古自治区-包头市
广东省-潮州市
四川省-阿坝藏族羌族自治州
四川省-凉山彝族自治州
山东省-临沂市
安徽省-淮南市
黑龙江省-黑河市
云南省-玉溪市
青海省-海北藏族自治州
山西省-临汾市
甘肃省-天水市
安徽省-宣城市
内蒙古自治区-鄂尔多斯市
山西省-晋中市
安徽省-马鞍山市
广东省-江门市
河北省-张家口市
广东省-揭阳市
海南省-琼中黎族苗族自治县
陕西省-延安市
山西省-忻州市
湖南省-怀化市
广东省-汕头市
福建省-泉州市
西藏自治区-林芝市
陕西省-汉中市
浙江省-金华市
湖北省-宜昌市
贵州省-铜仁市
山东省-潍坊市
河南省-濮阳市
新疆维吾尔自治区-石河子市
湖北省-仙桃市
广东省-湛江市
陕西省-榆林市
河南省-安阳市
山东省-德州市
山东省-淄博市
河南省-新乡市
河南省-商丘市
山东省-滨州市
贵州省-毕节市
贵州省-黔西南布依族苗族自治州
湖南省-湘西土家族苗族自治州
山东省-枣庄市
西藏自治区-阿里地区
台湾省-台北市
台湾省-高雄市
台湾省-台北市

  */
}
export const geoWorldData = {
  马来西亚: [101.975766, 4.210484],
  印度尼西亚: [113.921327, -0.789275],
  泰国: [100.992541, 15.870032],
  菲律宾: [121.774017, 12.879721],
  文莱: [114.727669, 4.535277],
  越南: [108.277199, 14.058324],
  老挝: [102.495496, 19.85627],
  缅甸: [95.956223, 21.913965],
  柬埔寨: [104.990963, 12.565679],
  新加坡: [103.51, 1.17],
  蒙古: [103.846656, 46.862496],
  俄罗斯: [105.318756, 61.52401],
  东帝汶: [125.727539, -8.874217],
  印度: [78.96288, 20.593684],
  巴基斯坦: [69.34511599999999, 30.375321],
  孟加拉国: [90.356331, 23.684994],
  斯里兰卡: [80.77179699999999, 7.873053999999999],
  阿富汗: [67.709953, 33.93911],
  尼泊尔: [84.12400799999999, 28.394857],
  马尔代夫: [73.5, 4.2],
  不丹: [90.433601, 27.514162],
  沙特阿拉伯: [45.079162, 23.885942],
  阿联酋: [53.847818, 23.424076],
  阿曼: [55.923255, 21.512583],
  伊朗: [53.688046, 32.427908],
  土耳其: [35.243322, 38.963745],
  以色列: [34.851612, 31.046051],
  埃及: [30.802498, 26.820553],
  科威特: [47.481766, 29.31166],
  伊拉克: [43.679291, 33.223191],
  卡塔尔: [51.183884, 25.354826],
  约旦: [36.238414, 30.585164],
  黎巴嫩: [35.862285, 33.854721],
  巴林: [50.36, 26.12],
  也门: [48.516388, 15.552727],
  叙利亚: [38.996815, 34.80207499999999],
  巴勒斯坦: [34.28, 31.3],
  波兰: [19.145136, 51.919438],
  罗马尼亚: [24.96676, 45.943161],
  捷克共和国: [15.472962, 49.81749199999999],
  斯洛伐克: [19.699024, 48.669026],
  保加利亚: [25.48583, 42.733883],
  匈牙利: [19.503304, 47.162494],
  拉脱维亚: [24.603189, 56.879635],
  立陶宛: [23.881275, 55.169438],
  斯洛文尼亚: [14.995463, 46.151241],
  爱沙尼亚: [25.013607, 58.595272],
  克罗地亚: [15.2, 45.1],
  阿尔巴尼亚: [20.168331, 41.153332],
  塞尔维亚: [21.005859, 44.016521],
  马其顿: [21.745275, 41.608635],
  波黑: [43.52, 18.26],
  黑山: [19.37439, 42.708678],
  哈萨克斯坦: [66.923684, 48.019573],
  乌兹别克斯坦: [64.585262, 41.377491],
  土库曼斯坦: [59.556278, 38.969719],
  吉尔吉斯斯坦: [74.766098, 41.20438],
  塔吉克斯坦: [71.276093, 38.861034],
  乌克兰: [31.16558, 48.379433],
  白俄罗斯: [27.953389, 53.709807],
  格鲁吉亚: [43.356892, 42.315407],
  阿塞拜疆: [47.576927, 40.143105],
  亚美尼亚: [45.038189, 40.069099],
  摩尔多瓦: [28.369885, 47.411631],
  挪威: [8.468945999999999, 60.47202399999999],
  丹麦: [9.501785, 56.26392],
  瑞典: [18.643501, 60.12816100000001],
  芬兰: [25.748151, 61.92410999999999],
  英国: [-3.435973, 55.378051],
  荷兰: [5.291265999999999, 52.132633],
  爱尔兰: [-8.24389, 53.41291],
  比利时: [4.469936, 50.503887],
  卢森堡: [6.129582999999999, 49.815273],
  法国: [2.213749, 46.227638],
  西班牙: [-3.74922, 40.46366700000001],
  葡萄牙: [-8.224454, 39.39987199999999],
  德国: [10.451526, 51.165691],
  奥地利: [14.550072, 47.516231],
  瑞士: [8.227511999999999, 46.818188],
  美国: [-95.712891, 37.09024],
  加拿大: [-102.646409, 59.994255],
  澳大利亚: [133.775136, -25.274398],
  新西兰: [174.885971, -40.900557],
  意大利: [12.5, 41.8],
  日本: [139.44, 35.41],
  斐济: [178.25, -18.08],
  巴布亚新几内亚: [147.07, -9.3],
  墨西哥: [-117.01, 32.32],
  北朝鲜: [125.3, 39.0],
  冰岛: [-21.58, 64.09],
  秘鲁: [-76.55, -12.06],
  加纳: [0.15, 5.33],
  苏丹: [32.36, 15.34],
  乍得: [14.59, 12.1],
  南非: [25.36, -33.57],
  赞比亚: [28.14, -15.2],
  韩国: [127.03, 37.35],
  刚果: [15.14, -4.14],
  肯尼亚: [36.49, -1.17],
  刚果民主共和国: [27.29, -11.44],
  中非: [18.37, 4.23],
  尼日利亚: [8.31, 12.0],
  阿尔及利亚: [3.13, 36.42],
  尼日尔: [2.05, 13.32],
  安哥拉: [13.2, -8.5],
  阿根廷: [-57.32, -38.0],
  贝宁: [2.47, 6.3],
  乌拉圭: [-56.11, -34.53],
  智利: [-70.4, -33.27],
  哥伦比亚: [-74.05, 4.38],
  委内瑞拉: [-66.58, 10.3],
  巴拉圭: [-57.4, -25.15],
  西撒哈拉: [-13.12, 27.09],
  巴西: [-47.3, -15.5],

  上海: [121.4648, 31.2891],
  东莞: [113.8953, 22.901],
  东营: [118.7073, 37.5513],
  中山: [113.4229, 22.478],
  临汾: [111.4783, 36.1615],
  临沂: [118.3118, 35.2936],
  丹东: [124.541, 40.4242],
  丽水: [119.5642, 28.1854],
  乌鲁木齐: [87.9236, 43.5883],
  佛山: [112.8955, 23.1097],
  保定: [115.0488, 39.0948],
  兰州: [103.5901, 36.3043],
  包头: [110.3467, 41.4899],
  北京: [116.4551, 40.2539],
  北海: [109.314, 21.6211],
  南京: [118.8062, 31.9208],
  南宁: [108.479, 23.1152],
  南昌: [116.0046, 28.6633],
  南通: [121.1023, 32.1625],
  厦门: [118.1689, 24.6478],
  台州: [121.1353, 28.6688],
  合肥: [117.29, 32.0581],
  呼和浩特: [111.4124, 40.4901],
  咸阳: [108.4131, 34.8706],
  哈尔滨: [127.9688, 45.368],
  唐山: [118.4766, 39.6826],
  嘉兴: [120.9155, 30.6354],
  大同: [113.7854, 39.8035],
  大连: [122.2229, 39.4409],
  天津: [117.4219, 39.4189],
  太原: [112.3352, 37.9413],
  威海: [121.9482, 37.1393],
  宁波: [121.5967, 29.6466],
  宝鸡: [107.1826, 34.3433],
  宿迁: [118.5535, 33.7775],
  常州: [119.4543, 31.5582],
  广州: [113.5107, 23.2196],
  廊坊: [116.521, 39.0509],
  延安: [109.1052, 36.4252],
  张家口: [115.1477, 40.8527],
  徐州: [117.5208, 34.3268],
  德州: [116.6858, 37.2107],
  惠州: [114.6204, 23.1647],
  成都: [103.9526, 30.7617],
  扬州: [119.4653, 32.8162],
  承德: [117.5757, 41.4075],
  拉萨: [91.1865, 30.1465],
  无锡: [120.3442, 31.5527],
  日照: [119.2786, 35.5023],
  昆明: [102.9199, 25.4663],
  杭州: [119.5313, 29.8773],
  浙江: [119.5313, 29.8773],
  枣庄: [117.323, 34.8926],
  柳州: [109.3799, 24.9774],
  株洲: [113.5327, 27.0319],
  武汉: [114.3896, 30.6628],
  汕头: [117.1692, 23.3405],
  江门: [112.6318, 22.1484],
  沈阳: [123.1238, 42.1216],
  沧州: [116.8286, 38.2104],
  河源: [114.917, 23.9722],
  泉州: [118.3228, 25.1147],
  泰安: [117.0264, 36.0516],
  泰州: [120.0586, 32.5525],
  济南: [117.1582, 36.8701],
  济宁: [116.8286, 35.3375],
  海口: [110.3893, 19.8516],
  淄博: [118.0371, 36.6064],
  淮安: [118.927, 33.4039],
  深圳: [114.5435, 22.5439],
  清远: [112.9175, 24.3292],
  温州: [120.498, 27.8119],
  渭南: [109.7864, 35.0299],
  湖州: [119.8608, 30.7782],
  湘潭: [112.5439, 27.7075],
  滨州: [117.8174, 37.4963],
  潍坊: [119.0918, 36.524],
  烟台: [120.7397, 37.5128],
  玉溪: [101.9312, 23.8898],
  珠海: [113.7305, 22.1155],
  盐城: [120.2234, 33.5577],
  盘锦: [121.9482, 41.0449],
  河北: [114.4995, 38.1006],
  福州: [119.4543, 25.9222],
  秦皇岛: [119.2126, 40.0232],
  绍兴: [120.564, 29.7565],
  聊城: [115.9167, 36.4032],
  肇庆: [112.1265, 23.5822],
  舟山: [122.2559, 30.2234],
  苏州: [120.6519, 31.3989],
  莱芜: [117.6526, 36.2714],
  菏泽: [115.6201, 35.2057],
  营口: [122.4316, 40.4297],
  葫芦岛: [120.1575, 40.578],
  衡水: [115.8838, 37.7161],
  衢州: [118.6853, 28.8666],
  西宁: [101.4038, 36.8207],
  西安: [109.1162, 34.2004],
  贵阳: [106.6992, 26.7682],
  连云港: [119.1248, 34.552],
  邢台: [114.8071, 37.2821],
  邯郸: [114.4775, 36.535],
  郑州: [113.4668, 34.6234],
  鄂尔多斯: [108.9734, 39.2487],
  重庆: [107.7539, 30.1904],
  金华: [120.0037, 29.1028],
  铜川: [109.0393, 35.1947],
  镇江: [119.4763, 31.9702],
  长春: [125.8154, 44.2584],
  长沙: [113.0823, 28.2568],
  长治: [112.8625, 36.4746],
  阳泉: [113.4778, 38.0951],
  青岛: [120.4651, 36.3373],
  韶关: [113.7964, 24.7028],
  银川: [106.26667, 38.46667],
  克拉玛依: [84.87, 45.6],
  香港特别行政区: [114.15, 22.15],
  石家庄: [114.29, 38.03],
  滁州: [119.0, 32.41],
  焦作: [113.13, 35.14],
  赣州: [114.93, 25.83],
  驻马店: [114.02, 32.98],
  赤峰: [118.92, 42.27],
  桂林: [110.28, 25.28],
  平顶山: [113.18, 33.77],
  开封: [114.3, 34.8],
  漳州: [117.65, 24.52],
  黄冈: [114.87, 30.45],
  酒泉: [98.52, 39.75],
  日喀则: [115.469381, 35.246531],
  阜新: [121.67, 42.02],
  黄山: [118.33, 29.72],
  果洛藏族自治州: [100.23, 34.48],
  牡丹江: [129.6, 44.58],
  芜湖: [118.38, 31.33],
  阜阳: [115.82, 32.9],
  武威: [102.63, 37.93],
  兴安盟: [122.05, 46.08],
  九江: [116.0, 29.7],
  临沧: [100.08, 23.88],
  朔州: [112.43, 39.33],
  吉安: [114.98, 27.12],
  张掖: [100.45, 38.93],
  衡阳: [112.57, 26.9],
  巴音郭楞蒙古自治州: [86.15, 41.77],
  上饶: [117.97, 28.45],
  桃园: [121.3, 24.97],
  信阳: [114.07, 32.13],
  邵阳: [111.47, 27.25],
  塔城地区: [82.98, 46.75],
  佳木斯: [130.37, 46.82],
  安庆: [117.05, 30.53],
  甘孜藏族自治州: [101.97, 30.05],
  郴州: [113.02, 25.78],
  吕梁: [111.13, 37.52],
  巴彦淖尔: [107.42, 40.75],
  襄阳: [112.144146, 32.042426],
  龙岩: [117.03, 25.1],
  潮州: [116.62, 23.67],
  阿坝藏族羌族自治州: [102.22, 31.9],
  凉山彝族自治州: [102.27, 27.9],
  淮南: [117.0, 32.63],
  黑河: [127.48, 50.25],
  海北藏族自治州: [100.9, 36.97],
  天水: [105.72, 34.58],
  宣城: [118.75, 30.95],
  晋中: [112.75, 37.68],
  马鞍山: [118.5, 31.7],
  揭阳: [116.37, 23.55],
  琼中黎族苗族自治县: [109.839996, 19.03557],
  忻州: [112.73, 38.42],
  怀化: [110.0, 27.57],
  林芝: [94.48391, 29.57562],
  汉中: [107.02, 33.07],
  宜昌: [111.28, 30.7],
  铜仁: [109.191555, 27.718346],
  濮阳: [115.03, 35.77],
  石河子: [86.03, 44.3],
  仙桃: [113.45, 30.37],
  湛江: [110.35, 21.27],
  榆林: [109.73, 38.28],
  安阳: [114.38, 36.1],
  新乡: [113.9, 35.3],
  商丘: [115.65, 34.45],
  毕节: [105.28501, 27.301693],
  黔西南布依族苗族自治州: [104.897971, 25.08812],
  湘西土家族苗族自治州: [109.73, 28.32],
  阿里地区: [80.1, 32.5],
  台北: [121.5, 25.03],
  高雄: [120.28, 22.62]
}
export const nameMap = {
  Afghanistan: '阿富汗',
  Singapore: '新加坡',
  Angola: '安哥拉',
  Albania: '阿尔巴尼亚',
  'United Arab Emirates': '阿联酋',
  Argentina: '阿根廷',
  Armenia: '亚美尼亚',
  'French Southern and Antarctic Lands': '法属南半球和南极领地',
  Australia: '澳大利亚',
  Austria: '奥地利',
  Azerbaijan: '阿塞拜疆',
  Burundi: '布隆迪',
  Belgium: '比利时',
  Benin: '贝宁',
  'Burkina Faso': '布基纳法索',
  Bangladesh: '孟加拉国',
  Bulgaria: '保加利亚',
  'The Bahamas': '巴哈马',
  'Bosnia and Herzegovina': '波斯尼亚和黑塞哥维那',
  Belarus: '白俄罗斯',
  Belize: '伯利兹',
  Bermuda: '百慕大',
  Bolivia: '玻利维亚',
  Brazil: '巴西',
  Brunei: '文莱',
  Bhutan: '不丹',
  Botswana: '博茨瓦纳',
  'Central African Republic': '中非共和国',
  Canada: '加拿大',
  Switzerland: '瑞士',
  Chile: '智利',
  China: '中国',
  'Ivory Coast': '象牙海岸',
  Cameroon: '喀麦隆',
  'Dem. Rep. Congo': '刚果民主共和国',
  'Republic of the Congo': '刚果共和国',
  Colombia: '哥伦比亚',
  'Costa Rica': '哥斯达黎加',
  Cuba: '古巴',
  'Northern Cyprus': '北塞浦路斯',
  Cyprus: '塞浦路斯',
  'Czech Republic': '捷克共和国',
  Germany: '德国',
  Djibouti: '吉布提',
  Denmark: '丹麦',
  'Dominican Republic': '多明尼加共和国',
  Algeria: '阿尔及利亚',
  Ecuador: '厄瓜多尔',
  Egypt: '埃及',
  Eritrea: '厄立特里亚',
  Spain: '西班牙',
  Estonia: '爱沙尼亚',
  Ethiopia: '埃塞俄比亚',
  Finland: '芬兰',
  Fiji: '斐济',
  'Falkland Islands': '福克兰群岛',
  France: '法国',
  Gabon: '加蓬',
  'United Kingdom': '英国',
  Georgia: '格鲁吉亚',
  Ghana: '加纳',
  Guinea: '几内亚',
  Gambia: '冈比亚',
  'Guinea Bissau': '几内亚比绍',
  Greece: '希腊',
  Greenland: '格陵兰',
  Guatemala: '危地马拉',
  'French Guiana': '法属圭亚那',
  Guyana: '圭亚那',
  Honduras: '洪都拉斯',
  Croatia: '克罗地亚',
  Haiti: '海地',
  Hungary: '匈牙利',
  Indonesia: '印度尼西亚',
  India: '印度',
  Ireland: '爱尔兰',
  Iran: '伊朗',
  Iraq: '伊拉克',
  Iceland: '冰岛',
  Israel: '以色列',
  Italy: '意大利',
  Jamaica: '牙买加',
  Jordan: '约旦',
  Japan: '日本',
  Kazakhstan: '哈萨克斯坦',
  Kenya: '肯尼亚',
  Kyrgyzstan: '吉尔吉斯斯坦',
  Cambodia: '柬埔寨',
  Kosovo: '科索沃',
  Kuwait: '科威特',
  Laos: '老挝',
  Lebanon: '黎巴嫩',
  Liberia: '利比里亚',
  Libya: '利比亚',
  'Sri Lanka': '斯里兰卡',
  Lesotho: '莱索托',
  Lithuania: '立陶宛',
  Luxembourg: '卢森堡',
  Latvia: '拉脱维亚',
  Morocco: '摩洛哥',
  Moldova: '摩尔多瓦',
  Madagascar: '马达加斯加',
  Mexico: '墨西哥',
  Macedonia: '马其顿',
  Mali: '马里',
  Myanmar: '缅甸',
  Montenegro: '黑山',
  Mongolia: '蒙古',
  Mozambique: '莫桑比克',
  Mauritania: '毛里塔尼亚',
  Malawi: '马拉维',
  Malaysia: '马来西亚',
  Namibia: '纳米比亚',
  'New Caledonia': '新喀里多尼亚',
  Niger: '尼日尔',
  Nigeria: '尼日利亚',
  Nicaragua: '尼加拉瓜',
  Netherlands: '荷兰',
  Norway: '挪威',
  Nepal: '尼泊尔',
  'New Zealand': '新西兰',
  Oman: '阿曼',
  Pakistan: '巴基斯坦',
  Panama: '巴拿马',
  Peru: '秘鲁',
  Philippines: '菲律宾',
  'Papua New Guinea': '巴布亚新几内亚',
  Poland: '波兰',
  'Puerto Rico': '波多黎各',
  'North Korea': '北朝鲜',
  Portugal: '葡萄牙',
  Paraguay: '巴拉圭',
  Qatar: '卡塔尔',
  Romania: '罗马尼亚',
  Russia: '俄罗斯',
  Rwanda: '卢旺达',
  'Western Sahara': '西撒哈拉',
  'Saudi Arabia': '沙特阿拉伯',
  Sudan: '苏丹',
  'S. Sudan': '南苏丹',
  Senegal: '塞内加尔',
  'Solomon Islands': '所罗门群岛',
  'Sierra Leone': '塞拉利昂',
  'El Salvador': '萨尔瓦多',
  Somaliland: '索马里兰',
  Somalia: '索马里',
  'Republic of Serbia': '塞尔维亚',
  Suriname: '苏里南',
  Slovakia: '斯洛伐克',
  Slovenia: '斯洛文尼亚',
  Sweden: '瑞典',
  Swaziland: '斯威士兰',
  Syria: '叙利亚',
  Chad: '乍得',
  Togo: '多哥',
  Thailand: '泰国',
  Tajikistan: '塔吉克斯坦',
  Turkmenistan: '土库曼斯坦',
  'East Timor': '东帝汶',
  'Trinidad and Tobago': '特里尼达和多巴哥',
  Tunisia: '突尼斯',
  Turkey: '土耳其',
  'United Republic of Tanzania': '坦桑尼亚',
  Uganda: '乌干达',
  Ukraine: '乌克兰',
  Uruguay: '乌拉圭',
  'United States': '美国',
  Uzbekistan: '乌兹别克斯坦',
  Venezuela: '委内瑞拉',
  Vietnam: '越南',
  Vanuatu: '瓦努阿图',
  'West Bank': '西岸',
  Yemen: '也门',
  'South Africa': '南非',
  Zambia: '赞比亚',
  Korea: '韩国',
  Tanzania: '坦桑尼亚',
  Zimbabwe: '津巴布韦',
  Congo: '刚果',
  'Central African Rep.': '中非',
  Serbia: '塞尔维亚',
  'Bosnia and Herz.': '波黑',
  'Czech Rep.': '捷克',
  'W. Sahara': '西撒哈拉',
  'Lao PDR': '老挝',
  'Dem.Rep.Korea': '朝鲜',
  'Falkland Is.': '福克兰群岛',
  'Timor-Leste': '东帝汶',
  'Solomon Is.': '所罗门群岛',
  Palestine: '巴勒斯坦',
  'N. Cyprus': '北塞浦路斯',
  Aland: '奥兰群岛',
  'Fr. S. Antarctic Lands': '法属南半球和南极陆地',
  Mauritius: '毛里求斯',
  Comoros: '科摩罗',
  'Eq. Guinea': '赤道几内亚',
  'Guinea-Bissau': '几内亚比绍',
  'Dominican Rep.': '多米尼加',
  'Saint Lucia': '圣卢西亚',
  Dominica: '多米尼克',
  'Antigua and Barb.': '安提瓜和巴布达',
  'U.S. Virgin Is.': '美国原始岛屿',
  Montserrat: '蒙塞拉特',
  Grenada: '格林纳达',
  Barbados: '巴巴多斯',
  Samoa: '萨摩亚',
  Bahamas: '巴哈马',
  'Cayman Is.': '开曼群岛',
  'Faeroe Is.': '法罗群岛',
  'IsIe of Man': '马恩岛',
  Malta: '马耳他共和国',
  Jersey: '泽西',
  'Cape Verde': '佛得角共和国',
  'Turks and Caicos Is.': '特克斯和凯科斯群岛',
  'St. Vin. and Gren.': '圣文森特和格林纳丁斯'
}
