var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"journalPortrait"},[(_vm.showYYQK)?_c('div',{staticClass:"YYQK-distributed mb-20"},[_c('div',{staticClass:"echarts-title"},[_vm._m(0),_c('div',{staticClass:"top"},[_c('div',{staticClass:"mr-8",class:_vm.YYQKActive === 'totalCount3,desc' ? 'active' : '',attrs:{"data-track":"增值服务-发文分析-引用期刊分布-近3年"},on:{"click":function($event){_vm.YYQKActive = 'totalCount3,desc'}}},[_vm._v(" 近3年 ")]),_c('div',{staticClass:"mr-8",class:_vm.YYQKActive === 'totalCount5,desc' ? 'active' : '',attrs:{"data-track":"增值服务-发文分析-引用期刊分布-近5年"},on:{"click":function($event){_vm.YYQKActive = 'totalCount5,desc'}}},[_vm._v(" 近5年 ")])])]),_c('div',{style:({
        width: '100%',
        height: '420px'
      }),attrs:{"id":"chartYYQK"}})]):_vm._e(),(_vm.showYZQK)?_c('div',{staticClass:"YZQK-distributed mb-20"},[_c('div',{staticClass:"echarts-title"},[_vm._m(1),_c('div',{staticClass:"top"},[_c('div',{staticClass:"mr-8",class:_vm.YZQKActive === 'totalCount3,desc' ? 'active' : '',attrs:{"data-track":"增值服务-发文分析-引证期刊分布-近3年"},on:{"click":function($event){_vm.YZQKActive = 'totalCount3,desc'}}},[_vm._v(" 近3年 ")]),_c('div',{staticClass:"mr-8",class:_vm.YZQKActive === 'totalCount5,desc' ? 'active' : '',attrs:{"data-track":"增值服务-发文分析-引证期刊分布-近5年"},on:{"click":function($event){_vm.YZQKActive = 'totalCount5,desc'}}},[_vm._v(" 近5年 ")])])]),_c('div',{style:({
        width: '100%',
        height: '420px'
      }),attrs:{"id":"chartYZQK"}})]):_vm._e(),(_vm.showTLYQK)?_c('div',{staticClass:"TLYQK-distributed mb-20"},[_c('div',{staticClass:"echarts-title"},[_vm._m(2),_c('div',{staticClass:"top"},[_c('div',{staticClass:"mr-8",class:_vm.TLYQKActive === 'totalCount3,desc' ? 'active' : '',attrs:{"data-track":"增值服务-发文分析-同领域期刊分布-近3年"},on:{"click":function($event){_vm.TLYQKActive = 'totalCount3,desc'}}},[_vm._v(" 近3年 ")]),_c('div',{staticClass:"mr-8",class:_vm.TLYQKActive === 'totalCount5,desc' ? 'active' : '',attrs:{"data-track":"增值服务-发文分析-同领域期刊分布-近5年"},on:{"click":function($event){_vm.TLYQKActive = 'totalCount5,desc'}}},[_vm._v(" 近5年 ")])])]),_c('div',{style:({
        width: '100%',
        height: '420px'
      }),attrs:{"id":"chartTLYQK"}})]):_vm._e(),(_vm.JGFWTJTableData.length > 0)?_c('div',{staticClass:"statistics-issued-by-institutions mb-20"},[_c('div',{staticClass:"echarts-title"},[_vm._m(3),_c('div',{staticClass:"top"},[_c('div',{staticClass:"mr-8",class:_vm.JGFWTJActive === 'totalCount3,desc' ? 'active' : '',attrs:{"data-track":"增值服务-发文分析-本刊机构发文统计-近3年"},on:{"click":function($event){_vm.JGFWTJActive = 'totalCount3,desc'}}},[_vm._v(" 近3年 ")]),_c('div',{staticClass:"mr-8",class:_vm.JGFWTJActive === 'totalCount5,desc' ? 'active' : '',attrs:{"data-track":"增值服务-发文分析-本刊机构发文统计-近5年"},on:{"click":function($event){_vm.JGFWTJActive = 'totalCount5,desc'}}},[_vm._v(" 近5年 ")])])]),_c('commonTable',{ref:"JGFWTJTable",attrs:{"table-data":_vm.JGFWTJTableData,"column-data":_vm.JGFWTJColumnData,"init-obj":_vm.JGFWTJPageObj},on:{"onChangeSort":(prop, order) => {
          _vm.handleChangeSort(prop, order, 'JGFWTJ')
        },"onChangePageData":(pageObj) => {
          _vm.getJGFWTJTableData(pageObj)
        }}})],1):_vm._e(),(_vm.TLYJGTableData.length > 0)?_c('div',{staticClass:"TLYJG mb-20"},[_c('div',{staticClass:"echarts-title"},[_vm._m(4),_c('div',{staticClass:"top"},[_c('div',{staticClass:"mr-8",class:_vm.TLYJGActive === 'totalCount3,desc' ? 'active' : '',attrs:{"data-track":"增值服务-发文分析-同领域机构发文统计-近3年"},on:{"click":function($event){_vm.TLYJGActive = 'totalCount3,desc'}}},[_vm._v(" 近3年 ")]),_c('div',{staticClass:"mr-8",class:_vm.TLYJGActive === 'totalCount5,desc' ? 'active' : '',attrs:{"data-track":"增值服务-发文分析-同领域机构发文统计-近5年"},on:{"click":function($event){_vm.TLYJGActive = 'totalCount5,desc'}}},[_vm._v(" 近5年 ")])])]),_c('commonTable',{ref:"TLYJGTable",attrs:{"table-data":_vm.TLYJGTableData,"column-data":_vm.TLYJGColumnData,"init-obj":_vm.TLYJGPageObj},on:{"onChangeSort":(prop, order) => {
          _vm.handleChangeSort(prop, order, 'TLYJG')
        },"onChangePageData":(pageObj) => {
          _vm.getTLYJGTableData(pageObj)
        }}})],1):_vm._e(),(_vm.YYJGTableData.length > 0)?_c('div',{staticClass:"YYJG-distributed mb-20"},[_c('div',{staticClass:"echarts-title"},[_vm._m(5),_c('div',{staticClass:"top"},[_c('div',{staticClass:"mr-8",class:_vm.YYJGActive === 'totalCount3,desc' ? 'active' : '',attrs:{"data-track":"增值服务-发文分析-引用机构分布-近3年"},on:{"click":function($event){_vm.YYJGActive = 'totalCount3,desc'}}},[_vm._v(" 近3年 ")]),_c('div',{staticClass:"mr-8",class:_vm.YYJGActive === 'totalCount5,desc' ? 'active' : '',attrs:{"data-track":"增值服务-发文分析-引用机构分布-近5年"},on:{"click":function($event){_vm.YYJGActive = 'totalCount5,desc'}}},[_vm._v(" 近5年 ")])])]),_c('commonTable',{ref:"YYJGTable",attrs:{"table-data":_vm.YYJGTableData,"column-data":_vm.YYJGColumnData,"init-obj":_vm.YYJGPageObj},on:{"onChangeSort":(prop, order) => {
          _vm.handleChangeSort(prop, order, 'YYJG')
        },"onChangePageData":(pageObj) => {
          _vm.getYYJGTableData(pageObj)
        }}})],1):_vm._e(),(_vm.YZJGTableData.length > 0)?_c('div',{staticClass:"YZJG-distributed mb-20"},[_c('div',{staticClass:"echarts-title"},[_vm._m(6),_c('div',{staticClass:"top"},[_c('div',{staticClass:"mr-8",class:_vm.YZJGActive === 'totalCount3,desc' ? 'active' : '',attrs:{"data-track":"增值服务-发文分析-引证机构分布-近3年"},on:{"click":function($event){_vm.YZJGActive = 'totalCount3,desc'}}},[_vm._v(" 近3年 ")]),_c('div',{staticClass:"mr-8",class:_vm.YZJGActive === 'totalCount5,desc' ? 'active' : '',attrs:{"data-track":"增值服务-发文分析-引证机构分布-近5年"},on:{"click":function($event){_vm.YZJGActive = 'totalCount5,desc'}}},[_vm._v(" 近5年 ")])])]),_c('commonTable',{ref:"YZJGTable",attrs:{"table-data":_vm.YZJGTableData,"column-data":_vm.YZJGColumnData,"init-obj":_vm.YZJGPageObj},on:{"onChangeSort":(prop, order) => {
          _vm.handleChangeSort(prop, order, 'YZJG')
        },"onChangePageData":(pageObj) => {
          _vm.getYZJGTableData(pageObj)
        }}})],1):_vm._e(),(_vm.keywordsTableData.length > 0)?_c('div',{staticClass:"keywords mb-20"},[_c('div',{staticClass:"echarts-title"},[_vm._m(7),_c('div',{staticClass:"top"},[_c('div',{staticClass:"mr-8",class:_vm.keywordsActive === 'totalCount3,desc' ? 'active' : '',attrs:{"data-track":"增值服务-发文分析-关键词统计-近3年"},on:{"click":function($event){_vm.keywordsActive = 'totalCount3,desc'}}},[_vm._v(" 近3年 ")]),_c('div',{staticClass:"mr-8",class:_vm.keywordsActive === 'totalCount5,desc' ? 'active' : '',attrs:{"data-track":"增值服务-发文分析-关键词统计-近5年"},on:{"click":function($event){_vm.keywordsActive = 'totalCount5,desc'}}},[_vm._v(" 近5年 ")])])]),_c('commonTable',{ref:"keywordsTable",attrs:{"table-data":_vm.keywordsTableData,"column-data":_vm.keywordsColumnData,"init-obj":_vm.keywordsPageObj},on:{"onChangeSort":(prop, order) => {
          _vm.handleChangeSort(prop, order, 'keywords')
        },"onChangePageData":(pageObj) => {
          _vm.getKeywordsTableData(pageObj)
        }}})],1):_vm._e(),(_vm.coocwordsTableData.length > 0)?_c('div',{staticClass:"coocwords mb-20"},[_c('div',{staticClass:"echarts-title"},[_vm._m(8),_c('div',{staticClass:"top"},[_c('div',{staticClass:"mr-8",class:_vm.coocwordsActive === 'totalCount3,desc' ? 'active' : '',attrs:{"data-track":"增值服务-发文分析-共现词统计-近3年"},on:{"click":function($event){_vm.coocwordsActive = 'totalCount3,desc'}}},[_vm._v(" 近3年 ")]),_c('div',{staticClass:"mr-8",class:_vm.coocwordsActive === 'totalCount5,desc' ? 'active' : '',attrs:{"data-track":"增值服务-发文分析-共现词统计-近5年"},on:{"click":function($event){_vm.coocwordsActive = 'totalCount5,desc'}}},[_vm._v(" 近5年 ")])])]),_c('commonTable',{ref:"coocwordsTable",attrs:{"table-data":_vm.coocwordsTableData,"column-data":_vm.coocwordsColumnData,"init-obj":_vm.coocwordsPageObj},on:{"onChangeSort":(prop, order) => {
          _vm.handleChangeSort(prop, order, 'coocwords')
        },"onChangePageData":(pageObj) => {
          _vm.getCoocwordsTableData(pageObj)
        }}})],1):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"second-title"},[_c('span',[_vm._v("引用期刊分布")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"second-title"},[_c('span',[_vm._v("引证期刊分布")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"second-title"},[_c('span',[_vm._v("同领域期刊分布")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"second-title"},[_c('span',[_vm._v("本刊机构发文统计")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"second-title"},[_c('span',[_vm._v("同领域机构发文统计")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"second-title"},[_c('span',[_vm._v("引用机构分布")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"second-title"},[_c('span',[_vm._v("引证机构分布")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"second-title"},[_c('span',[_vm._v("关键词统计")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"second-title"},[_c('span',[_vm._v("共现词统计")])])
}]

export { render, staticRenderFns }